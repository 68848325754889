<template>
  <div class="person-card">
    <div class="d-none d-lg-flex justify-content-between align-items-center ">
      <div class="d-flex align-items-center">
        <img v-if="profileImg" class="mr-2" :src="profileImg" alt="" />
        <img
          v-else
          class="mr-2"
          src="../assets/images/profile/noprofile.png"
          alt=""
        />
        <p class="mb-0 mr-3 flex-shrink-0" style="width:140px">
          {{ firstName }} {{ lastName }}
        </p>
        <p class="m-0">กำลังเรียน: {{ courseName || "-" }}</p>
      </div>
      <div
        class="d-flex align-items-center ml-auto mr-3 flex-shrink-0"
        style="width:140px"
      >
        <b-progress
          class="w-100 mr-2"
          height="7px"
          :value="progress"
          :max="100"
          :precision="2"
        ></b-progress>
        <p
          class="text-right font-weight-bold color-primary m-0 flex-shrink-0"
          style="width:40px"
        >
          {{ progress || 0 }}%
        </p>
      </div>
      <p
        class="color-secondary font-weight-bold m-0 flex-shrink-0"
        style="cursor: pointer;"
        @click="seeDetail"
      >
        <u>
          ดูเพิ่มเติม
        </u>
      </p>
    </div>
    <div class="d-flex align-items-center justify-content-between d-lg-none">
      <div class="d-flex align-items-center">
        <img v-if="profileImg" class="mr-2" :src="profileImg" alt="" />
        <img
          v-else
          class="mr-2"
          src="../assets/images/profile/noprofile.png"
          alt=""
        />
        <div class="d-flex flex-column">
          <p class="m-0 font-weight-bold">{{ firstName }} {{ lastName }}</p>
          <p class="m-0">กำลังเรียน: {{ courseName || "-" }}</p>
          <div class="d-flex align-items-center" style="width:150px">
            <b-progress
              class="w-100 mr-2"
              height="7px"
              :value="progress"
              :max="100"
              :precision="2"
            ></b-progress>
            <p class="font-weight-bold color-primary m-0">
              {{ progress || 0 }}%
            </p>
          </div>
        </div>
      </div>
      <p
        class="color-secondary font-weight-bold m-0 align-self-start flex-shrink-0"
        @click="seeDetail"
      >
        <u>
          ดูเพิ่มเติม
        </u>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["detail"],
  props: [
    "id",
    "profileImg",
    "firstName",
    "lastName",
    "courseName",
    "progress",
  ],
  methods: {
    seeDetail() {
      this.$emit("detail", {
        id: this.id,
        name: `${this.firstName} ${this.lastName}`,
      });
    },
  },
};
</script>

<style scoped>
.person-card {
  background-color: #f1f1f1;
  padding: 10px;
  margin: 15px 0;
}
.person-card {
  background-color: #f1f1f1;
  padding: 10px;
}

.person-card img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
</style>
