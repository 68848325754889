<template>
  <div>
    <base-dialog
      xsmall
      :show="displaySuggestLink"
      @close="displaySuggestLink = false"
    >
      <div class="suggest text-center">
        <h4>QR code</h4>
        <h4>สำหรับการแนะนำ</h4>
        <qrcode-vue
          class="my-3"
          :value="suggestLink"
          :size="size"
          level="H"
        ></qrcode-vue>
        <p class="text-big">{{ suggestLink }}</p>
        <button class="secondary" @click="copyRefLink">
          <p>Copy Link</p>
        </button>
      </div>
    </base-dialog>
    <div v-if="page === 'suggestion'">
      <div class="d-flex justify-content-between">
        <h3 class="font-weight-bold mb-4">การแนะนำของฉัน</h3>
        <p
          class="text-big font-weight-bold color-secondary"
          style="cursor: pointer;"
          @click="displaySuggestLink = true"
        >
          <u>แนะนำ</u>
        </p>
      </div>
      <p class="text-big font-weight-bold">คนที่แนะนำฉัน</p>
      <div v-if="!isLoading">
        <PersonCard
          v-if="parentRef"
          :id="parentRef.user_id"
          :profileImg="parentRef.img_url"
          :firstName="parentRef.first_name"
          :lastName="parentRef.last_name"
          :courseName="parentLatestCourse"
          :progress="parentProgress"
          @detail="seeDetail"
        />
        <div v-else>
          <p class="color-text text-center text-big">ไม่มีคนที่แนะนำฉัน</p>
        </div>
        <p class="text-big font-weight-bold">คนที่ฉันแนะนำ</p>
        <div v-if="refUsers">
          <div v-for="user in refUsers" :key="user.ref_user_id">
            <PersonCard
              :id="user.ref_user_id"
              :profileImg="user.ref_user_img"
              :firstName="user.ref_user_first_name"
              :lastName="user.ref_user_last_name"
              :courseName="user.latest_course ? user.latest_course : null"
              :progress="user.progress ? Math.ceil(user.progress) : null"
              @detail="seeDetail"
            />
          </div>
        </div>
        <div v-else>
          <p class="color-text text-center text-big">ไม่มีคนที่ฉันแนะนำ</p>
        </div>
      </div>
      <div v-else class="d-flex justify-content-center">
        <b-spinner></b-spinner>
      </div>
    </div>
    <div v-if="page === 'detail'">
      <h3 class="font-weight-bold">
        <span
          style="margin-right:10px; cursor: pointer;"
          @click="page = 'suggestion'"
          >&#x276E;</span
        >
        {{ userName }}
      </h3>
      <p class="text-big font-weight-bold">
        คอร์สเรียนที่สนใจทั้งหมด ({{
          userFinishedCourse.length + userInprogressCourse.length
        }})
      </p>
      <p class="text-big font-weight-bold m-0">
        สำเร็จ ({{ userFinishedCourse.length }})
      </p>
      <div v-if="userFinishedCourse.length === 0">
        <p class="color-text text-center text-big">ไม่มีคอร์สที่จะแสดง</p>
      </div>
      <div
        v-for="course in userFinishedCourse"
        :key="course.course.course_id"
        class="d-flex justify-content-between flex-wrap"
      >
        <p class="mb-1">{{ course.course.title }}</p>
        <div
          class="d-flex align-items-center mr-3 mb-2 flex-shrink-0"
          style="width:140px"
        >
          <b-progress
            class="w-100 mr-2"
            height="7px"
            :value="course.progress"
            :max="100"
            :precision="2"
          ></b-progress>
          <p class="font-weight-bold color-primary m-0" style="min-width: 40px">
            {{ Math.ceil(course.progress) }}%
          </p>
        </div>
      </div>
      <p class="text-big font-weight-bold m-0">
        กำลังเรียน ({{ userInprogressCourse.length }})
      </p>
      <div v-if="userInprogressCourse.length === 0">
        <p class="color-text text-center text-big">ไม่มีคอร์สที่จะแสดง</p>
      </div>
      <div
        v-for="course in userInprogressCourse"
        :key="course.course.course_id"
        class="d-flex justify-content-between flex-wrap"
      >
        <p class="mb-1">{{ course.course.title }}</p>
        <div
          class="d-flex align-items-center mr-3 mb-2 flex-shrink-0"
          style="width:140px"
        >
          <b-progress
            class="w-100 mr-2"
            height="7px"
            :value="course.progress"
            :max="100"
            :precision="2"
          ></b-progress>
          <p class="font-weight-bold color-primary m-0" style="min-width: 40px">
            {{ Math.ceil(course.progress) }}%
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../api/faAPI";
import baseUrl from "../../util/backend";
import PersonCard from "../../components/PersonCard.vue";
import QrcodeVue from "qrcode.vue";
export default {
  components: {
    PersonCard,
    QrcodeVue,
  },
  data() {
    return {
      displaySuggestLink: false,
      isLoading: false,
      page: "suggestion",
      parentRef: null,
      parentLatestCourse: "",
      parentProgress: 0,
      refUsers: null,
      // ----- user Detail ------- //
      userName: "",
      userFinishedCourse: [],
      userInprogressCourse: [],
      // <----- user Detail -------> //
      size: 120,
    };
  },
  computed: {
    suggestLink() {
      return `${window.location.protocol}//${window.location.hostname}/register?refCode=${this.$store.getters.getUser.self_ref_code}`;
    },
  },
  methods: {
    copyRefLink() {
      navigator.clipboard.writeText(this.suggestLink);
      alert("คัดลอกรหัสอ้างอิงสังกัดหรือบุคคลแล้ว");
    },
    seeDetail(user) {
      console.log(user);
      this.page = "detail";
      this.userName = user.name;
      this.getRefDetail(user.id);
    },
    async loadRefUser() {
      this.isLoading = true;
      try {
        const res = await axios.get(`${baseUrl}/getMyAdvice`, {
          params: { user_id: this.$store.getters.getUser.user_id },
        });
        console.log(res.data.data);
        const refData = res.data.data;
        if (refData.parent) {
          this.parentRef = refData.parent.parent;
        }
        if (refData.parent_latest_course) {
          this.parentLatestCourse = refData.parent_latest_course;
          this.parentProgress = refData.parent_progress;
        }
        this.refUsers = refData.refUsers;
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
      this.isLoading = false;
    },
    async getRefDetail(id) {
      this.userFinishedCourse = [];
      this.userInprogressCourse = [];
      const res = await axios.get(`${baseUrl}/getUserCourseAdvice`, {
        params: { user_id: id },
      });
      console.log(res.data.data);
      const allCourse = res.data.data;
      for (const course of allCourse) {
        if (course.progress === 100) {
          this.userFinishedCourse.push(course);
        } else {
          this.userInprogressCourse.push(course);
        }
      }
      // console.log(this.userFinishedCourse);
      // console.log(this.userInprogressCourse);
    },
  },
  created() {
    this.loadRefUser();
  },
};
</script>

<style scoped>
.extend-dialog {
  max-width: 300px;
}
.text-line {
  width: 100%;
  max-width: 400px;
  text-align: center;
  border-bottom: 3px solid #0430ad;
  line-height: 0.1em;
  margin: 40px auto;
}

.text-line span {
  background: #fff;
  padding: 0 10px;
}

button.secondary {
  display: block;
  background-color: #07c5f8;
  padding: 10px;
  margin: 20px auto;
  width: 100%;
  color: #fff;
}

* >>> .progress {
  background-color: #c4c4c4 !important;
  border-radius: 0;
}
* >>> .progress-bar {
  background-color: #0430ad !important;
}
</style>
